.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.hero {
  background: #7b39b6;
  background: linear-gradient(to top, #7b39b6 0%, #f0f0f0 40%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(-0.015em -0.015em 0.002em #7b39b6)
    drop-shadow(0.01em 0.025em 0.002em #7b39b6)
    drop-shadow(0.015em 0.03em 0.002em #7b39b6)
    drop-shadow(0.01em 0.08em 0.002em rgba(0, 0, 0, 0.1));
  position: relative;
  z-index: 2;
}

.backgroundContainer {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
      to bottom,
      rgba(8, 17, 24, 1) 10%,
      rgba(8, 17, 24, 0.3) 30%,
      rgba(8, 17, 24, 0) 40%,
      rgba(8, 17, 24, 0) 60%,
      rgba(8, 17, 24, 0.3) 70%,
      rgba(8, 17, 24, 1) 100%
    ),
    radial-gradient(
      ellipse at center,
      rgba(8, 17, 24, 1) 0%,
      rgba(8, 17, 24, 0) 50%,
      rgba(8, 17, 24, 1) 100%
    );
  z-index: 2;
}

.buttonGroup {
  display: flex;
  width: 242px;
  height: 36px;
  background-color: #7b39b633;
  border-radius: 0px 4px 4px 0px;
  border-bottom: 2px solid #7b39b6;
  opacity: 1;
}

.button {
  flex: 1;
  border: none;
  color: white;
  padding: 0;
  font-size: 10px;
  font-weight: bold;
  transition: background-color 0.3s;
  cursor: pointer;
  background-color: #7b39b633;
  line-height: 36px;
}

.buttonActive {
  background-color: #7b39b699;
}

.buttonInactive {
  background-color: #7b39b633;
}

.button:focus {
  outline: none;
}

@media (min-width: 1000px) {
  .max-width-large {
    max-width: 70vw;
  }
}

@media (max-width: 1023px) {
  .max-width-small {
    max-width: 90vw;
  }
}

@media (min-width: 1024px) {
  .max-width-services {
    max-width: 90vw;
  }
}

.backgroundContainer {
  position: relative;
  height: 250px;
  width: 100%;
  overflow: hidden;
}

.backgroundBlur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.backgroundColorOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 0, 150, 0.2);
  z-index: 0;
}

.backgroundOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(14, 25, 34, 0) 0%, #081118 100%),
    linear-gradient(0deg, rgba(14, 25, 34, 0) 0%, #081118 100%);
  z-index: 1;
}

.overlay {
  position: relative;
  z-index: 2;
}

.hero {
  color: #ffffff6c;
  font-weight: bold;
  z-index: 3;

  font-size: 3 rem;
  letter-spacing: 2px;
}
